/*
change scrool bar for all the app
*/

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
input[type="checkbox"]:disabled {
  background: red;
}
input[type="checkbox"][disabled] {
  background: red;
  outline: red;
}

input[type="checkbox"]:disabled {
  background: red;
  border: 10px solid #d4d4d5;
}

.react-datepicker-wrapper {
  width: 100%;
}
