.sys-text-mobile {
  display: none;
}
@media (max-width: 980px) {
  .sys-text {
    font-size: 15px;
    margin-top: 1.4rem;
    margin-right: 1rem;
  }
}
@media (max-width: 500px) {
  .sys-text {
    font-size: 15px;
    margin-top: 1.4rem;
    margin-right: 0.3rem;
    display: none;
  }
  .sys-text-mobile {
    display: block;
    font-size: 11px;
    margin-top: 1.3rem;
    margin-right: 0.3rem;
  }
}
@media (max-width: 360px) {
  .sys-text {
    font-size: 15px;
    margin-top: 1.4rem;
    margin-right: 0.3rem;
    display: none;
  }
  .sys-text-mobile {
    display: block;
    font-size: 10px;
    margin-top: 1.2rem;
    margin-right: 0.3rem;
  }
}

@media (min-width: 989px) {
  .images {
    max-width: 40%;
    max-height: 40%;
    object-fit: contain;
    margin-top: 1.3rem;
  }
  .sys-text {
    font-size: 10px;
    margin-top: 1rem;
  }
}
@media (min-width: 1439px) {
  .images {
    max-width: 12rem;
    min-height: 3.75rem;
    object-fit: contain;
    margin-top: 0.5rem;
  }
  .sys-text {
    font-size: 14px;
    margin-top: 1rem;
  }
}
@media (min-width: 1919px) {
  .images {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    margin-top: 0.1rem;
  }
  .sys-text {
    margin-top: 1.2rem;
    font-size: 15px;
  }
}
.search {
  width: 35%;
}
