.add-row {
  label {
    margin-right: 0.3rem;
    margin-bottom: 0;
  }
}
input {
  margin: 0;
}
input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
button:focus {
  border-color: #05501c;
  box-shadow: 0 0 0 0.2rem rgba(2, 71, 14, 0.25);
}
// .custom-check {
//     display: none;
// }

// label:before {
//     background: rgb(199, 194, 194);
//     border: 1px solid #035f8f;
//     height: 25px;
//     border-radius: 20%;

//     width: 25px;
//     display: block;
//     cursor: pointer;
// }
// .custom-check + label:before {
//     content: '';
//     background: rgb(199, 194, 194);
//     border-color: #3d9000;
//     color: #96be0a;
//     font-size: 27px;
//     border-radius: 20%;

//     line-height: 24px;
//     text-align: center;
// }

// .custom-check:disabled + label:before {
//     border-color: #eee;
//     color: #ccc;
//     background: rgb(199, 194, 194);
//     border-radius: 20%;
// }

// .custom-check:checked + label:before {
//     content: '✓';
//     color: rgb(6, 10, 9);

//     border-radius: 20%;

// }